import React from 'react';
import ProjectActive from 'modules/Project/Active/ProjectActive';

const Team = () => (
  <>
    <h1>Project Statistics</h1>
    <ProjectActive />
  </>
);

export default Team;
